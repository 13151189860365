/* Order Create, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="order-create" class="entity-create">
      <v-card-title>Create Order</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-alert
          v-if="serverErrorMessage"
          type="error"
        >
          {{ serverErrorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
        <v-btn
          color="primary"
          class="me-3"
          @click="Save"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiContentSaveOutline  }}
          </v-icon>
          <span>Save</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          @click="Back"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Reference</td>
        <td><v-text-field
      label="Reference"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Reference').map(x => x.error)"
      v-model="entity.Reference"
      :rules="[validators.required]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>OrderStatus</td>
        <td><OrderStatusLookup v-model="entity.StatusId" :rules="[validators.requiredInteger]" :error-messages="serverErrors.filter(x => x.field === 'StatusId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Product</td>
        <td><ProductLookup v-model="entity.ProductId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'ProductId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Manifest</td>
        <td><ManifestLookup v-model="entity.ManifestId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'ManifestId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Member</td>
        <td><MemberLookup v-model="entity.MemberId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'MemberId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Delivery Address</td>
        <td><AddressLookup :filter="{ MemberId: entity.MemberId, IsDeleted: false }" v-model="entity.DeliveryAddressId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'DeliveryAddressId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Billing Address</td>
        <td><AddressLookup :filter="{ MemberId: entity.MemberId, IsDeleted: false }" v-model="entity.BillingAddressId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'BillingAddressId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Retailer</td>
        <td><v-text-field
      label="Retailer"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'RetailerName').map(x => x.error)"
      v-model="entity.RetailerName"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Retailer Reference</td>
        <td><v-text-field
      label="Retailer Reference"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'RetailerReference').map(x => x.error)"
      v-model="entity.RetailerReference"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Retailer Tracking Reference</td>
        <td><v-text-field
      label="Retailer Tracking Reference"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'RetailerTrackingReference').map(x => x.error)"
      v-model="entity.RetailerTrackingReference"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Retailer Order Date</td>
        <td><DateField v-model="entity.RetailerOrderDate" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'RetailerOrderDate').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Retailer Expected Delivery Date</td>
        <td><DateField v-model="entity.RetailerExpectedDeliveryDate" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'RetailerExpectedDeliveryDate').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Retailer Delivery Charge</td>
        <td><v-text-field
      label="Retailer Delivery Charge"
      solo-inverted
			prefix="£"
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'RetailerDeliveryCharge').map(x => x.error)"
      v-model="entity.RetailerDeliveryCharge"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Importation Customs Declaration</td>
        <td><v-checkbox v-model="entity.ImportationCustomsDeclaration" :color="(entity.ImportationCustomsDeclaration ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Original Retailer Documentation</td>
        <td><v-checkbox v-model="entity.OriginalRetailerDocumentation" :color="(entity.OriginalRetailerDocumentation ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Terms and Conditions</td>
        <td><v-checkbox v-model="entity.TermsAndConditions" :color="(entity.TermsAndConditions ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Discount Code</td>
        <td><v-text-field
      label="Discount Code"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'DiscountCode').map(x => x.error)"
      v-model="entity.DiscountCode"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Custom Length</td>
        <td><v-text-field
      label="Custom Length"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'CustomLength').map(x => x.error)"
      v-model="entity.CustomLength"
      :rules="[validators.integerValidator,validators.integerRange(entity.CustomLength, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Custom Width</td>
        <td><v-text-field
      label="Custom Width"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'CustomWidth').map(x => x.error)"
      v-model="entity.CustomWidth"
      :rules="[validators.integerValidator,validators.integerRange(entity.CustomWidth, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Custom Height</td>
        <td><v-text-field
      label="Custom Height"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'CustomHeight').map(x => x.error)"
      v-model="entity.CustomHeight"
      :rules="[validators.integerValidator,validators.integerRange(entity.CustomHeight, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Quote Price</td>
        <td><v-text-field
      label="Quote Price"
      solo-inverted
			prefix="£"
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'QuotePrice').map(x => x.error)"
      v-model="entity.QuotePrice"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Item Value</td>
        <td><v-text-field
      label="Item Value"
      solo-inverted
			prefix="£"
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'ItemValue').map(x => x.error)"
      v-model="entity.ItemValue"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Item Description</td>
        <td><v-text-field
      label="Item Description"
      solo-inverted
			flat
      :error-messages="serverErrors.filter(x => x.field === 'ItemDescription').map(x => x.error)"
      v-model="entity.ItemDescription"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>


        </tbody>
      </v-simple-table>
      </v-form>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'
import OrderStatusLookup from '@/views/orderstatus/OrderStatusLookup'
import ProductLookup from '@/views/product/ProductLookup'
import ManifestLookup from '@/views/manifest/ManifestLookup'
import MemberLookup from '@/views/member/MemberLookup'
import AddressLookup from '@/views/address/AddressLookup'

import {
  mdiContentSaveOutline,
  mdiChevronLeft
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    DateField,
    OrderStatusLookup,
		ProductLookup,
		ManifestLookup,
		MemberLookup,
		AddressLookup
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = ref({

    });
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/createOrder', {
          entity: entity.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-view', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const Back = () => {
      router.go(-1);
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      Back,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiChevronLeft
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-create::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-create::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-create::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-create::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-create::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-create::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-create::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-create::v-deep .v-input--selection-controls {
  margin: 10px;
}
</style>

